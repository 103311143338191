/**
 * Theme for NPR US site
 */
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeVarsUsLW, buildThemeMixinsUsLW, buildThemeWidgetStylesUsLW } from 'dw-us-law/theme';
import { gothamFont } from '../common/nextFont';

export const buildThemeVarsUsNPR = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const nprRed = '#DA252B'; //color1
  const nprDarkRed = '#991A1E'; //color2
  const nprGrey = '#EDEDED'; //color6
  const nprMaxRed = '#D62021';
  const nprRadioBlue = '#1E7FC3';
  const grey60 = '#616161';
  const nprRed1 = '#E92440';

  //secondary
  const nprSvgCheckboxBg = '%231E7FC3';

  const gotham = gothamFont.style.fontFamily;

  const lwThemeVars = buildThemeVarsUsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'NPR US',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-with-partner.svg`,
    },
    colors: {
      primary1: nprRed,
      primary1BtnHover: nprDarkRed,
      btnDisabled: nprGrey,
      secondaryArticleHeader: nprMaxRed,
      btnsPrimaryBg: nprRed,
      btnsPrimaryHoverBg: nprDarkRed,
      radioBtnsPrimaryBg: nprRadioBlue,
      textPrimaryBg: nprRed,
      grey60,
      textRed: nprRed1,
      btnsSecondaryHoverBg: nprDarkRed,
      stockPill: nprRed,
      cardBackground: nprRadioBlue,
      svgCheckboxBg: nprSvgCheckboxBg,
    },
    fonts: {
      familyPrimary: gotham,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: gotham,
      sizeH1: '35px',
      headerMainNavTitle: gotham,
      headerMainNavSubTitle: gotham,
      weightMedium: '325',
    },
  });
};
export const buildThemeMixinsUsNPR = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsUsLW(vars);

  return merge({}, buildThemeWidget, {
    hxl: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize44};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize64};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize40};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize36};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize40};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize30};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize36};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize24};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize22};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize20};
      line-height: ${vars.fonts.fontLineHeight115};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize24};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight115};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize18};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize16};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize12};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize10};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize10};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing9};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight100};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-style: ${vars.fonts.styleItalic};
      font-size: ${vars.fonts.fontSize20};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightNormal};
      font-size: ${vars.fonts.fontSize16};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightNormal};
      font-size: ${vars.fonts.fontSize14};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightNormal};
      font-size: ${vars.fonts.fontSize12};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightNormal};
      font-size: ${vars.fonts.fontSize12};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
  });
};
export const buildThemeWidgetStylesUsNPR = (vars: Omit<Theme, 'widgets'>) => {
  const themeStyles = buildThemeWidgetStylesUsLW(vars);

  return merge({}, themeStyles, {
    Footer: {
      default: `
      ${themeStyles.Footer.default}
      .footer-bottom-section {
        .wrapper {
          .brand img {
              max-width: 20em;
              ${vars.breakpoints.lg} {
                max-width: 23.5em;
              }
            }
          }

          .copyrights {
            &> p:last-child {
              margin-top: 1.125em;

              &> a {
                text-decoration: underline;

                &:first-of-type {
                  margin-right: 1em;
                }

                &:not(:first-of-type) {
                  margin-left: 1em;
                }
              }
            }
          }
        }
      }
    `,
      navItems: `
      ${themeStyles.Footer.navItems}
    `,
    },
    Search: {
      wrapper: `
      ${themeStyles.Search.wrapper}
      .addToCart-container {
        .cart-slider {
          button {
            font-weight: ${vars.fonts.weightMediumPlus};
          }
        }
      }
      `,
      hitWrapper: ` 
      ${themeStyles.Search.hitWrapper}
      &.list-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            .image-bg {
              .image-container {
                .offer {
                  .stock {
                    background: ${vars.colors.stockPill};
                  }
                }
              }
            }
          }
        } 
      }
      &.grid-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            .image-bg {
              .image-container {
                .offer {
                  .stock {
                    background: ${vars.colors.stockPill};
                  }
                }
              }
            }
          }
        }
      }
      `,
    },
    DrawerMenu: {
      mobileNavMenuContainer: `
      ${themeStyles.DrawerMenu.mobileNavMenuContainer}
      .mantine-Drawer-body & .level1Link span:hover {
        color: ${vars.colors.primary1};
      }
      .mantine-Drawer-body & a.level1Link:hover {
        color: ${vars.colors.primary1};
      }
      `,
    },
    CmsArticle: {
      wrapper: `
      ${themeStyles.CmsArticle.wrapper}
      .secondary {
        color: ${vars.colors.secondaryArticleHeader} !important;
      }`,
    },
    Cart: {
      MiniCart: `
      ${themeStyles.Cart.MiniCart}
      .mini-cart-header {
        h3 {
          font-weight: ${vars.fonts.weightBold};
        }
      }
        .price-section {
          font-weight: ${vars.fonts.weightMedium};
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .price-section .strike+.sale-price{
          color: ${vars.colors.textRed};
        }
        .price-section .strike+.member-price {
          color:${vars.colors.blue600};
        }
      `,
      slider: `
      h2 {
        &.mantine-Drawer-title {
          text-transform: capitalize !important;
          font-weight: ${vars.fonts.weightBold} !important;
        }
      }
      ${themeStyles.Cart.slider}
      `,
    },
    CmsTBSPage: {
      sideBarLegacy: `
        & .sidebar{
          display: none;
          ${vars.breakpoints.md} {
            display:initial;
          }
          & .grid-container{
             div{
              width:100%;
              font-family: ${vars.fonts.familyPrimary};
              font-weight: ${vars.fonts.weightBold};;
              .navigation-link{
                h4{
                  display:none;              
                }
                & a{
                  margin-left: 10px;
                  display:flex;
                  align-items:center;
                  gap:10px;
                  font-family:${vars.fonts.familyPrimary};
                  font-size: ${vars.fonts.fontSize16};
                  font-weight: ${vars.fonts.weightNormal};
                  line-height: 22.4px;
                  letter-spacing: 0.04em;
                  color: #237BBD;
                  &.active {
                    color: ${vars.colors.btnsTertiaryBg};
                  }
                }
              }
             }
          }
          & .cms-conditional-container{
             margin-bottom: 15px;
             margin-left: -20px;
              & .grid-container > div{
                 display:flex;
                 align-items: center;
                 font-family:${vars.fonts.oswald};
                 font-size: ${vars.fonts.fontSize18};
                 color: ${vars.colors.grey50};
                 padding-bottom:5px;
                 line-height: 25.2px;
                 letter-spacing: 0.04em;
                 gap:4px;
                    
                  .cms-image-container{
                   display:flex;
                   width: fit-content;
                   img{
                      width: 25px;
                   }
              }
            }
          }
        }
      `,
      breadcrumbCSS: `
        padding: 12px 24px 12px 0px;
        & a {
          display: flex;
          align-items: center;
          gap: 8px;
          &:hover, &:active, &:focus {
            text-decoration: none;
          }
          & .link-text {
            text-decoration: none;
            border-bottom: 1px solid ${vars.colors.primary8};
            font-size: ${vars.fonts.sizeH5};
            font-weight: bold;
          }
        }
      `,
    },
    Membership: {
      pageWrapper: `
      ${themeStyles.Membership.pageWrapper}
      .page-content {
        max-width: 1112px;
        margin: auto;
      }
      .intro-text{
          ${vars.mixins.h6}
          font-weight: 325;
          text-align: center;
          margin-top: 16px;
          margin-bottom: 16px;
          line-height: 27.6px;
      }
      .bottom-section{
        .widget-cms-column{
          .banner {
            .banner-bg-img {
              ${vars.breakpoints.lg} {
                height: 270px;
              }
            }
            .banner-image-wrapper {
              ${vars.breakpoints.lg} {
                max-height: 270px;
              }
            }
          }    
        }  
      }
      & .top-section{
          .dynamicComponents{
            >.widget-container{
             .cms-image-container{
                background: ${vars.colors.whiteWine};
                padding: 16px 0;
                // width:100%;
                display:flex;
                justify-content:center;
                img{
                  height: 130px;
                  box-sizing: content-box;
                }
              }
            }
            >.widget-cms-column{
              & .grid-container {
                    justify-content: space-between;

              }
            }
          }
        }
          & .content-section{
           & button.unlimited-modal-primary-cta{
         background-color: ${vars.colors.unltPopupBackground} !important;
           }
        }
      
      `,
    },
    MembershipRebranding: {
      membershipStatus: `
      ${themeStyles.MembershipRebranding.membershipStatus}
        border: 1px solid ${vars.colors.grey60};
        padding: 24px;
        h1.membership-status {
          font-family: ${vars.fonts.familyPrimary};
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.fontSize40};
          line-height: ${vars.fonts.fontSize40};
          color: ${vars.colors.unltPopupBackground};
          ${vars.breakpoints.sm} {            
            font-size: ${vars.fonts.fontSize48};
            line-height: ${vars.fonts.fontSize48};
          }
        }
        .re-join-note{
           margin-top: 12px;
        }

        .year-note {
          
          font-weight: ${vars.fonts.weightBold};
          font-family: ${vars.fonts.roboto};
          color: ${vars.colors.unltPopupBackground};
       
        }
        .add-unlimited-to-cart button{
           margin-top: 20px;
          background: ${vars.colors.unltPopupBackground};
          :hover{
          
           background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).LWDarkBlue};
           color: ${vars.colors.white};
            // border:1px solid black;
          }
        }
        .saving-instruction {
          font-family: ${vars.fonts.roboto};
          padding-top: 10px;
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeLarge};
        }
        .saving-note {
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeH5};
          color: ${vars.colors.grey60};
          margin-top: 12px;
        }
        .price-section {
          display: flex;
          align-items: baseline;
        }
        .membership-status-title {
          font-family: ${vars.fonts.familyPrimary};
          font-weight: 325;
          .savings-title {
            text-transform: initial;
          }
        }
        .membership-subscription-title {
          font-family: ${vars.fonts.roboto};
          font-weight: 700;
          font-size: ${vars.fonts.sizeLarge};
          padding-top: 10px;
        }
        .enroll-info {
          margin-top: 12px;
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          & .date {
            font-weight: ${vars.fonts.weightNormal};
            font-size: ${vars.fonts.sizeLarge};
            padding-right: 8px;
          }
        }
      `,
      payment: `
        margin-top: 24px;
        ${vars.breakpoints.sm} {            
          margin-top: 40px;
        }
        .payment-title{
          ${vars.mixins.h6}
          font-weight: 325;
        }
        .last-payment-row{
          display: flex;
          font-family: ${vars.fonts.roboto};
          font-size: ${vars.fonts.sizeLarge};
          color:${vars.colors.primary8};
          gap: 4px;
        }
        .last-payment-title{
          font-weight: ${vars.fonts.weightNormal};
          padding-right: 4px;
        }
        .payment-date{
          font-weight: ${vars.fonts.weightBold};
          .loaderContainer div {
            width: 20px;
            height: 20px;
            border-width: 2px;
          }
        }
      `,
      layout: `
         ${themeStyles.MembershipRebranding.layout}
         & .change-payment-card a{
           font-family: ${vars.fonts.roboto};
           text-decoration: underline;
           }
       `,
    },
    MyAccount: {
      unlimitedModalWrapper: `
       ${themeStyles.MyAccount.unlimitedModalWrapper}
        & .mantine-Modal-title{
          font-weight: ${vars.fonts.weightMedium};
        }
       .unlimited-modal-content-wrapper{
          gap:20px;
          .mantine-Text-root{
              font-family: ${vars.fonts.roboto};
              font-size: ${vars.fonts.fontSize12};
          }
          .heading-notes{
            font-size: ${vars.fonts.fontSize16};
            }
          }
         .unlimited-icons{
           i {
            color:  ${vars.colors.unltPopupBackground} !important;
            font-size: 50px;
            background: ${vars.colors.whiteWine};
            border-radius: 100%;
           }
           .notes .notes-heading{
             ${vars.mixins.eyebrowRegular};
             ${vars.breakpoints.sm} 
             {
              font-size: ${vars.fonts.fontSize12};
             }

            }
         }
         button.unlimited-modal-tertiary-cta {
          text-decoration: none;
         }
         button.unlimited-modal-primary-cta{
          background-color: ${vars.colors.unltPopupBackground};
          min-width:200px;
           &:hover{
              background-color: ${vars.colors.primary2};
           }
         }
    `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsUsNPR, buildThemeMixinsUsNPR, buildThemeWidgetStylesUsNPR);

export default createTheme;
